/**
 * Tailwind's utility classes, generated based on your config file.
 */

@import 'tailwindcss/utilities';

@layer utilities {
    .top-10 {
      top: 2.25rem;
    }
    .right-6 {
        right: 1.5rem;
    }
}
